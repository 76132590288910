.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  max-width: 1400px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo-spinning {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: inherit;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.link {
  color: aqua;
  text-decoration: aqua;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-child {
  flex: 1;
}

.flex-child:first-child {
  margin-right: 20px;
}

.enter-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 33vw;
  height: auto;
  padding: 30px;
  background-color: #000000;
  color: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0 0 40px rgba(255, 255, 255, 0.15);
  text-align: center;
}

.cover-modal {
  position: absolute;
  top: 5%;
  left: 5%;
  transform: translate(-5%, -5%);
  width: 95vw;
  height: auto;
  padding: 10px;
  background-color: #000000;
  color: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0 0 40px rgba(255, 255, 255, 0.15);
  text-align: center;
}

.preview-modal {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(-5%, -50%);
  width: 95vw;
  height: auto;
  padding: 10px;
  background-color: #000000;
  color: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0 0 40px rgba(255, 255, 255, 0.15);
  text-align: center;
}

.modal-button {
  display: inline;
  margin: 0;
}

.enter-images {
  max-width: 20vw;
  border: 2px solid #ffffff;
  cursor: pointer;
}

.page-content {
  min-width: 991px;
  max-width: 1323px;
  width: 96%;
  height: auto;
  margin: 8px auto;
}

.page-top {
  border-top: 1px solid #666666;
  max-width: 1323px;
  margin-bottom: 60px;
}

#header {
  width: 100%;
  padding-bottom: 8px;
}

#header-logo {
  width: 200px;
  margin-top: 2px;
  float: left;
  display: inline;
}

#page-title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: calc(10px + 2vmin);
  margin: 4px auto;
  padding-right: 200px;
  text-align: center;
}

#sign-in {
  float: right;
  width: 200px;
  display: inline;
}

.sign-in-button {
  margin: 8px;
}

.centered {
  text-align: center;
}

.masonry-flex {
  display: flex;
  flex-wrap: wrap;
  max-height: 220vh;
  margin: 16px auto;
  border: 1px solid #3f50b5;
  div {
    width: 15vw;
    color: white;
    margin: 8px;
    text-align: center;
  }
}

.cover-container {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #3f50b5;
}

.cover-images-div {
  flex: 0 0 calc(33.33% - 30px);
  max-width: 15vw;
  margin: 8px;
}

.cover-images {
  max-width: 15vw;
  border: 2px solid #ffffff;
  cursor: pointer;
}

.cover-images-large {
  max-height: 69vh;
  border: 2px solid #ffffff;
  cursor: pointer;
  margin: 15px auto;
}

.cover-images-modal {
  max-height: 95vh;
  border: 2px solid #ffffff;
  cursor: pointer;
  margin: 5px auto;
}

.cover-description {
  width: 33vw;
}

.preview-image {
  max-width: 50vw;
  margin: 5px auto 30px auto;
  cursor: pointer;
}

#breadcrumbs {
  float:left;
  display: block;
  width: 100%;
  text-align: left;
  padding: 12px 0 8px;
}

#footer {
  width: 40vw;
  text-align: center;
  margin: 60px auto 60px;
}

.social-icons-div {
  width: 40vw;
  height: 4vh;
  display: block;
  margin: 32px auto;
  flex: 0 0 calc(33.33% - 30px);
  flex-direction: column;
  text-align: center;
}

.social-icons {
  height: 32px;
  width: 32px;
  padding: 4px;
  border: 1px solid aqua;
  border-radius: 8px;
  background-color: #444444;
  display: inline-block;
  margin: 8px;
}

@media only screen and (max-width: 766px) {
  .enter-modal {
    position: absolute;
    width: 80vw;
    padding: 15px;
  }
  .modal-button {
    margin: 15px auto;
    display: inline-block;
  }
  .enter-images {
    max-width: 60vw;
    margin: 15px auto;
  }
  .page-content {
    min-width: 90vw;
    max-width: 95vw;
    width: 90vw;
  }
  .masonry-flex {
    max-height: none;
    div {
      width: 75vw;
    }
  }
  .cover-images-div, .cover-images {
    max-width: 75vw;
  }
  .cover-description {
    width: 80vw;
  }
  .preview-image {
    max-width: 90vw;
  }
  #page-title {
    padding-right: 0;
    margin-bottom: 15px;
    margin-top: 0;
  }
  #footer {
    width: 80vw;
  }
  .social-icons-div {
    width: 33vw;
    height: 4vh;
  }
}
